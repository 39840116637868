import { findAll } from "@/services/local/tumoral-phenomena-service";
import { transformCategoriesToString } from "@/utils/categories";
import { DiagnoseTumoralPhenomena } from "@/domains/therapy/steps/diagnose/domain/DiagnoseTumoralPhenomena";

const initialState = () => ({
  tumoralPhenomena: [],
  selected: {},
  relatedParesOfSelected: [],
});

const state = initialState();

const mutations = {
  setTumoralPhenomena(state, value) {
    state.tumoralPhenomena = value;
  },
  selectTumoralPhenomenon(state, value) {
    state.selected = JSON.parse(JSON.stringify(value));
  },
  clearSelected(state) {
    state.selected = {};
    state.relatedParesToSelected = [];
  },
  setRelatedParesOfSelected(state, newValue) {
    state.relatedParesOfSelected = newValue;
  },
  clear(state) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadTumoralPhenomena({ commit }) {
    const tumoralPhenomena = await findAll();
    commit("setTumoralPhenomena", tumoralPhenomena);
  },
  selectTumoralPhenomenon({ commit, dispatch }, tumoralPhenomenon) {
    commit("selectTumoralPhenomenon", tumoralPhenomenon);
    dispatch(
      "resolutionZones/showDialog",
      {
        returnAction: "tumoralPhenomena/handleReturnResolutionZone",
      },
      { root: true }
    );
  },
  async handleReturnResolutionZone(
    { state, commit, dispatch, rootGetters },
    {
      therapistComments,
      patientComments,
      resolutionZone,
      affectedSpotParTrauma,
      trackingPointSide,
      impactionPointSide,
      parTraumaSide,
      hidePatient,
      patientImpactAtHome,
    }
  ) {
    const title = "Checar associações patogênicas";
    const message = `Recomendamos que o sejam checadas as associações patogências deste fenômeno tumoral.
    
    Associações patogênicas: ${transformCategoriesToString(
      state.selected.categories
    )}
    
    Instruções: ${state.selected.instructions}
    
    Deseja checar?`;

    const userWantsToCheckCategories = await dispatch(
      "settings/openConfirm",
      { title, message },
      { root: true }
    );

    const diagnose = new DiagnoseTumoralPhenomena({
      tumoralPhenomenon: state.selected,
      checkedCategories: userWantsToCheckCategories,
      therapistComments,
      patientComments,
      relatedPares: state.relatedParesOfSelected,
      diagnoseResolutionZone: {
        resolutionZone,
        affectedSpotParTrauma,
        trackingPointSide,
        impactionPointSide,
        parTraumaSide,
      },
      hidePatient,
      patientImpactAtHome,
    });
    console.log("diagnose", diagnose);

    commit("diagnosis/tumoralPhenomena/addToDiagnose", diagnose, {
      root: true,
    });

    if (userWantsToCheckCategories) {
      const ids = state.selected.categories.map((category) => category.id);
      dispatch(
        "checkCategories/startByIds",
        { ids, selectedType: "tumoralPhenomena" },
        { root: true }
      );
    }
  },
  addRelatedParesOfSelectedToDiagnose({ state, dispatch }) {
    dispatch(
      "diagnosis/tumoralPhenomena/addRelatedParesToDiagnose",
      {
        relatedParesIds: state.relatedParesOfSelected,
        tumoralPhenomenonId: state.selected.id,
      },
      { root: true }
    );
  },
};

const getters = {
  hasTumoralPhenomena(state) {
    return state.tumoralPhenomena.length > 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
